<template>
  <Menubar :model="navBarItems" class="w-full sticky top-0 z-50 min-h-[52px]" :pt="menubarStyles">
    <template #start>
      <Button
        v-tooltip.right="compacted ? 'Agrandir le menu' : 'Réduire le menu'"
        severity="secondary"
        :icon="compacted ? 'pi pi-chevron-right' : 'pi pi-bars'"
        class="!rounded-l-none !border-l-0 hover:!border-l-0 !border-surface-200 dark:!border-surface-700 !hidden md:!block"
        @click="emits('toggleCompact')" />
      <Button icon="pi pi-bars" text severity="secondary" class="ml-5 !block md:!hidden"
        @click="emits('toggleSidebarOpened')" />
      <div v-if="currentAcquisitionFile && !isAcquisitionFileListPage" class="text-sm font-extrabold mx-4 ">
        <div>
          {{ currentAcquisitionFile?.name }}
        </div>
        <div v-if="isAdmin(currentUser)" class="flex items-center gap-1 cursor-pointer" @click="router.push(`/account/infos`)">
          <i class="pi pi-user text-xs" />
          <UserUsername class="text-xs" :userId="currentAcquisitionFile?.userId" />
        </div>
        <div v-else class="flex items-center gap-1">
          <i class="pi pi-user text-xs" />
          <UserUsername class="text-xs" :userId="currentAcquisitionFile?.userId" />
        </div>
      </div>
      <LayoutTagBox v-if="currentAcquisitionFile && !isAcquisitionFileListPage" :key="currentAcquisitionFile?.id" />
    </template>
    <template #end>
      <div
        class="flex items-center gap-2 cursor-pointer hover:bg-surface-100 dark:hover:bg-surface-800 transition-all rounded-md py-1 px-2"
        @click="toggleUserMenu">
        <Avatar shape="circle">
          <template #icon>
            <i class="pi pi-user" />
          </template>
        </Avatar>
        <div class="text-sm font-bold hidden md:block">
          {{ currentUser?.name }}
          <div class="text-xs">
            {{ currentUser?.profileType }}
          </div>
        </div>
        <Popover ref="userMenu" class="" :pt="{ content: '!p-0' }">
          <div class="text-sm font-bold block md:hidden p-4">
            {{ currentUser?.name }}
            <div class="text-xs">
              {{ currentUser?.profileType }}
            </div>
          </div>
          <Menu :model="userMenuItems" :pt="{ root: '!border-none' }" />
        </Popover>
      </div>
    </template>
  </Menubar>
</template>

<script setup lang="ts">
const router = useRouter();
const currentUser = useCurrentUser();
const route = useRoute();

const { navBarItems, compacted } = defineProps({
  navBarItems: {
    type: Array,
    default: () => [],
  },
  compacted: {
    type: Boolean,
    default: false,
  },
});

const emits = defineEmits([
  "toggleUserMenu",
  "toggleCompact",
  "toggleSidebarOpened",
]);

const isAcquisitionFileListPage = computed(() => {
  return route.name === 'acquisitionFiles';
});


const menubarStyles = {
  root: "!border-l-0 !border-r-0 !border-t-0 !border-b min-h-14 !rounded-none !pl-0",
};

const currentAcquisitionFile = useCurrentAcquisitionFile();

const userMenu = ref(null);

const toggleUserMenu = (event) => {
  userMenu.value?.toggle(event);
};

const userMenuItems = ref([
  {
    label: "Mon profil d'investisseur",
    icon: "pi pi-wallet",
    command() {
      router.push("/account/buyer-profile");
    },
  },
  {
    label: "Mon compte",
    icon: "pi pi-user",
    command() {
      router.push("/account/infos");
    },
  },
  {
    label: "Déconnexion",
    icon: "pi pi-sign-out",
    command() {
      logout();
    },
  },
]);

const logout = async () => {
  await useFetch("/api/auth/logout", {
    method: "POST",
  });
  currentUser.value = null;
  useCookie("token").value = null;
  router.push("/");
};
</script>
