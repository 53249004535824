<template>
  <PanelMenu class="h-full" :model="items" :pt="panelMenuStyles">
    <template #item="{ item }">
      <NuxtLink
        v-ripple
        v-tooltip.right="compacted ? item.label : undefined"
        :to="item.to"
        class="flex items-center px-4 py-2 cursor-pointer group border-0"
        @click="emits('toggleSidebarOpened', false)"
      >
        <span :class="[item.icon, 'group-hover:text-inherit']" />
        <span
          v-if="!compacted"
          :class="['ml-2 font-bold text-sm', { 'font-semibold': item.items }]"
          >{{ item.label }}</span
        >

        <Badge
          v-if="item.tag && !compacted"
          class="ml-auto"
          :value="item.badge"
        />
      </NuxtLink>
    </template>
  </PanelMenu>
</template>

<script setup lang="ts">
const { items, compacted } = defineProps({
  items: {
    type: Array,
    default: () => [],
  },
  compacted: {
    type: Boolean,
    default: false,
  },
});

const panelMenuStyles = {
  panel: "!border-none !p-0",
  root: "bg-white dark:bg-surface-900 p-4 pt-2",
};

const emits = defineEmits(["toggleSidebarOpened"]);
</script>
