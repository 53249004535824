<template>
  <div
    class="h-screen shrink-0 absolute lg:static left-0 top-0 z-40 bg-white dark:bg-surface-900 border-r border-surface-200 dark:border-surface-700 transition-all"
  >
    <LayoutSidebarHeader :compacted="compacted" />
    <LayoutSidebarMenu
      :items="items"
      :compacted="compacted"
      @toggle-sidebar-opened="emits('toggleSidebarOpened', false)"
    />
  </div>
</template>

<script setup lang="ts">
const { items, compacted } = defineProps({
  items: {
    type: Array,
    default: () => [],
  },
  compacted: {
    type: Boolean,
    default: false,
  },
});

const emits = defineEmits(["toggleSidebarOpened"]);
</script>
